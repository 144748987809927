<template>
  <div class="container pd100">
    <div class="card mb10">
      <van-image class="card-media" :src="detail.course_cover" />
      <div class="card-inner">
        <div class="card-title ui-ellipsis">{{ detail.course_name }}</div>
        <div class="card-txt">{{ detail.classes_name }}</div>
        <div class="card-txt">{{ detail.class_date }} {{ detail.class_time }}</div>
      </div>
    </div>

    <van-cell title="上课资料" v-if="detail.course_knowledge" @click="toKnowledge" :label="detail.course_knowledge.knowledge_name"  is-link :url="detail.course_knowledge.knowledge_link" />
    <div v-if="detail.class_remark || detail.class_summary" class="mb10">
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item v-if="detail.class_remark" title="上课备注" name="1">
          <div>{{detail.class_remark}}</div>
        </van-collapse-item>
        <van-collapse-item v-if="detail.class_summary" title="上课内容" name="2">
          <div class="checkin-html ql-editor"  @click="toPreview($event)" v-html="detail.class_summary"></div>
        </van-collapse-item>
      </van-collapse>
    </div>

    <van-cell v-if="detail.course_id" :title="`排课${detail.reserve_count}人、到课${detail.signin_count}人、缺勤${detail.cancel_count}人、课消${all_book_costs}课时`" />
    <div class="checkin-table">
      <ve-table
        ref="ve_tables"
        v-if="!searchLoading"
        :columns="columns" 
        :table-data="course_students" 
        row-key-field-name="student_id"
        :checkbox-option="checkboxOption"
        
        :cell-selection-option="{enable: false}"
      />

      <!-- <ve-table
        ref="ve_tables"
        v-if="!searchLoading"
        :columns="columns" 
        :table-data="course_students" 
        row-key-field-name="student_id"
        :checkbox-option="checkboxOption"
        :scroll-width="400"
        :expand-option="expandOption"
        :cell-selection-option="{enable: false}"
      /> -->
      <div v-show="course_students.length == 0 && !searchLoading" class="v-table-empty-data">暂无数据</div>
    </div>
    <van-share-sheet
      v-model="showShare"
      title="学员操作"
      :description="student.student_name"
      :options="options"
      @select="onSelect"
    />
    <div class="affix-bar">
      <van-button type="primary" block @click="onSubmit">签到保存</van-button>
    </div>
    <div @click="toAdd" class="checkin-add">
      <van-icon size="36" color="#00DE93" name="add-o" />
    </div>
    <loadings :loading='searchLoading' />
  </div>
</template>

<script>
import { Checkbox, CheckboxGroup, Tag, Stepper, Collapse, CollapseItem, Field,Dialog,ShareSheet} from 'vant'
import PageMixin from '@/mixins/page'
import Loadings from '@/components/loading'
import { weWorkchatConfig } from '@/common/wx'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: 'TeacherScheduleDetail',
  components: {
    [Tag.name]: Tag,
    [ShareSheet.name]:ShareSheet,
    loadings:Loadings,
    [Stepper.name]: Stepper,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Collapse.name]: Collapse,
    [Dialog.name]:Dialog,
    [CollapseItem.name]: CollapseItem,
    [Field.name]: Field,
  },
  mixins: [PageMixin],
  data() {
    return {
      showShare: false,
      options: [
      ],
      isWxWork:false,
      searchLoading:false,
      detail: {},
      student: {},
      is_waitlist:false,
      has_demo:false,
      course_studio: {},
      course_students: [],
      checkedList: [],
      activeNames:0,
      course_cost_times:0,
      statusMap: {
        '0': { label: '缺勤', color: '#ff993e' },
        '1': { label: '待签到', color: '#f44' },
        '2': { label: '到课', color: '#00DE93' },
        '4': { label: '候补中', color: '#00DE93' }
      },
      columns: [
        // {width: 30, key: "a", align: "center" , type: 'expand',fixed:'left'},
        {
            field: "#",
            fixed:'left',
            key: "z",
            title: "#",
            width: 25,
            align: "center",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                return ++rowIndex;
            },
        },
        { field: "student_name", key: "student_name", title: "学员", align: "left", width: 65, renderBodyCell: ({ row, column, rowIndex }, h) => {return this.userInfo(row,rowIndex)},fixed:'left'},
        {width: 25, key: "b", align: "center" , type: 'checkbox'},
        {field: 'book_status', key: "book_status", title: '状态', width: 65, renderBodyCell: ({ row, column, rowIndex }, h) => {return this.bookStatus(row)}},
        {field: 'book_costs', key: "book_costs", title: `扣课`, width: 60, renderBodyCell: ({ row, column, rowIndex }, h) => {return this.bookCosts(row, column, rowIndex)}, isEdit: false},
        // {field: 'is_demo', key: "is_demo", title: '试听', width: 60, renderBodyCell: ({ row, column, rowIndex }, h) => {return this.isDemo(row, column, rowIndex)}, isEdit: false, align: "center"},
        {field: 'student_available_times', key: "student_available_times", title: '可用', width: 60, renderBodyCell: ({ row, column, rowIndex }, h) => {return this.studentAvailableTimes(row)}},
      ],

      checkboxOption: {
        // 可控属性
        selectedRowKeys: [],
        // 行选择改变事件
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => this.selectChange(selectedRowKeys),
        // 全选改变事件
        selectedAllChange: ({ isSelected, selectedRowKeys }) => this.selectALL(selectedRowKeys),
      },

      expandOption: {
          render: ({ row, column, rowIndex }, h) => this.expandOptionRender(row, column, rowIndex),
      },
      
    }
  },
  computed: {
    all_book_costs() {
      let all_book_costs = 0
      this.course_students.forEach(item=>{
        all_book_costs+=Number(item.book_costs);
      })
      return Number(all_book_costs.toFixed(2))
    }
  },
  created() {
    this.onDetail()
    let str = window.navigator.userAgent
      if(str.indexOf("wxwork") != -1){
          this.isWxWork=true   
      }
      if(this.isWxWork){
          weWorkchatConfig()
      }
  },
  methods: {
    onSelect(option){
      // 移除学员
      if(option.id === 1){
          // this.show = true
          // console.log(option);
          this.deleteStudent(this.student,option.rowIndex)
      }

      if(option.id === 2){
          // this.$router.push({path:'/teacher/invited/teacherApply',query:{task_id:this.task_id}})
          this.jumpCustomer(this.student)
      }

      if(option.id === 3){
        this.jumpStudent(this.student)
      }
    },
    toKnowledge(){
      this.$api.click_index({event_id:this.$route.query.course_class_id,event_type:'teacher_course_knowledge_detail'}).then(res=>{})
    },
    toShare(row, rowIndex){
      this.student = row;
      if(this.student.customer_id){
        this.options=[
          { name: '移除学员', icon: require('../../assets/cancel_audition.png'), id: 1 ,rowIndex:rowIndex},
          { name: '客户信息', icon: require('../../assets/follow.png'), id: 2 ,rowIndex:rowIndex},
          { name: '学员信息', icon: require('../../assets/homework.png'), id: 3 ,rowIndex:rowIndex},
        ];
      }else{
        this.options=[
          { name: '移除学员', icon: require('../../assets/cancel_audition.png'), id: 1 ,rowIndex:rowIndex},
          { name: '学员信息', icon: require('../../assets/homework.png'), id: 3 ,rowIndex:rowIndex},
        ];
      }
      this.showShare = true
    },
    onDetail() {
      this.searchLoading = true
      this.$api.employee_scheduledetail({ course_class_id: this.$route.query.course_class_id }).then(res => {
        this.detail = res.data
        this.course_studio = res.data.course_studio
        this.course_students = res.data.course_students
        this.course_students.forEach(item=>{
          if(item._checked){
            this.checkboxOption.selectedRowKeys.push(item.student_id)
          }
          if(item.is_demo){
            this.has_demo = true;
          }
          if(item.book_status==4){
            this.is_waitlist=true
          }
        })
        this.course_cost_times = res.data.course_cost_times
        this.columns[4].title = `(${res.data.course_cost_times})`
        this.searchLoading = false
      })
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true)
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll()
    },
    onSubmit() {
      // 签到
      // if (!this.selectedRecord.length) {
      //   this.$toast('未选中学员')
      //   return
      // }
      let arr = []
      for(let i=0; i<this.course_students.length; i++){
        arr.push({book_costs:this.course_students[i].book_costs?this.course_students[i].book_costs:'0',book_id:this.course_students[i].book_id,book_status:this.course_students[i].book_status})
        if(this.course_students[i].book_status == 1){
          this.$toast(`${this.course_students[i].student_name}的上课状态没有勾选~`)
          return false
        }
      }
      this.$toast.loading({
        message: '提交中...',
        forbidClick: true,
        duration:0
      })
      this.$api.employee_scheduleAttend({ course_class_id:this.$route.query.course_class_id,course_class_book: arr })
        .then(res => {
          this.$toast.success('签到成功')
          this.onDetail()
        })
        .catch(err => {
          this.$toast.fail('提交失败')
          console.error(err)
        })
    },
    toPreview(e){
        if (e.target.tagName == 'IMG') {
            this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'checkin_course_class_summary'}).then(res=>{})
            WeixinJSBridge.invoke("imagePreview", {
                "urls":this.richImages,
                "current": e.target.src.split('random=')[1] - 1
            });
        }
    },
    onSubmit2() {
      // 请假
      if (!this.checkedList.length) {
        this.$toast('未选中学员')
        return
      }
      this.$toast.loading({
        message: '提交中...',
        forbidClick: true
      })

      this.$api.employee_schedulecheckout({ book_id: this.checkedList })
        .then(res => {
          this.$toast.success('请假成功')
          // setTimeout(() => {
          //   this.$router.back()
          // }, 1500)
          this.onDetail()
        })
        .catch(err => {
          this.$toast.fail('提交失败')
          console.error(err)
        })
    },
    toAdd(){
      this.$router.push({path:'/teacher/checkin/add',query:{course_class_id:this.$route.query.course_class_id}})
    },
    selectALL(arr){
      if(!arr.length){
        this.checkboxOption.selectedRowKeys = arr
        this.course_students.forEach(item=>{
            item.book_costs = "0"
            item.book_status = 0
            item._checked = false
        })
        return true;
      }

      if(this.has_demo){
        Dialog.confirm({
          title: '',
          message: '当前课程包含试听课，试听课学员是否正常扣课。',
          confirmButtonText:'正常扣课',
          cancelButtonText:'不扣课时'
        })
          .then(() => {
              this.checkboxOption.selectedRowKeys = arr
              this.course_students.forEach(item=>{
                if(arr.indexOf(item.student_id) != -1){
                  if(item.book_costs<=0){
                    item.book_costs = this.course_cost_times
                  }
                  item.book_status = 2
                  item._checked = true
                }else{
                  item.book_costs = "0.0"
                  item.book_status = 0
                  item._checked = false
                }
              })
          })
          .catch(() => {
              this.checkboxOption.selectedRowKeys = arr
              this.course_students.forEach(item=>{
                if(arr.indexOf(item.student_id) != -1){
                  if(item.book_costs<=0){
                    item.book_costs = this.course_cost_times
                  }
                  if(item.is_demo){
                    item.book_costs = 0
                  }
                  item.book_status = 2
                  item._checked = true
                }else{
                  item.book_costs = "0"
                  item.book_status = 0
                  item._checked = false
                }
              })
          });
        }else{
              this.checkboxOption.selectedRowKeys = arr
              this.course_students.forEach(item=>{
                if(arr.indexOf(item.student_id) != -1){
                  if(item.book_costs<=0){
                    item.book_costs = this.course_cost_times
                  }
                  item.book_status = 2
                  item._checked = true
                }else{
                  item.book_costs = "0.0"
                  item.book_status = 0
                  item._checked = false
                }
              })
        }
    },
    selectChange(arr){
      this.checkboxOption.selectedRowKeys = arr
      this.course_students.forEach(item=>{
        if(arr.indexOf(item.student_id) != -1){
          if(item.book_costs<=0){
            item.book_costs = this.course_cost_times
          }
          item.book_status = 2
          item._checked = true
        }else{
          item.book_costs =  "0.0"
          item.book_status = 0
          item._checked = false
        }
      })
    },
    userInfo(rowData,rowIndex){
      if(rowData.is_demo){
        return (
            <div class="checkin-user">
                <a on-click={()=>this.toShare(rowData,rowIndex)} class="checkin-user-name">{rowData.student_name}</a>
                <van-icon name="https://i01.wedots.cn/2022/06/20/d91a26201e35220aa147b47aeef0b01f.png"  on-click={() => this.toChat(rowData)} v-show={rowData.wxwork_user_id && this.isWxWork}/>
                <span class="checkin-user-tag">试</span>
            </div>
        )
      }else{
        if(rowData.is_temp){
          return (
            <div class="checkin-user">
                <a on-click={()=>this.toShare(rowData,rowIndex)} class="checkin-user-name">{rowData.student_name}</a>
                <van-icon name="https://i01.wedots.cn/2022/06/20/d91a26201e35220aa147b47aeef0b01f.png"  on-click={() => this.toChat(rowData)} v-show={rowData.wxwork_user_id && this.isWxWork}/>
                <span class="checkin-user-tag">临</span>
            </div>
          )
        }else{
          return (
            <div class="checkin-user">
                <a on-click={()=>this.toShare(rowData,rowIndex)} class="checkin-user-name">{rowData.student_name}</a>
                <van-icon name="https://i01.wedots.cn/2022/06/20/d91a26201e35220aa147b47aeef0b01f.png"  on-click={() => this.toChat(rowData)} v-show={rowData.wxwork_user_id && this.isWxWork}/>
                <span class="checkin-user-tag"  v-show={rowData.book_status==4}>补</span>
            </div>
          )
        }
      }
    },
    bookStatus(rowData){
      return (
        <div>
          {this.statusMap[`${rowData.book_status}`].label}
        </div>
      )
    },
    bookCosts(row, column, rowIndex){
      return (
        <div>
          <van-field v-model={row.book_costs} input-align="center" type="number" />
        </div>
      )
    },
    isDemo(row, column, rowIndex){
      return (
        <div>
          <van-checkbox shape='square' checked-color="" v-model={row.is_demo}></van-checkbox>
        </div>
      )
    },
    studentAvailableTimes(rowData){
      if(Number(rowData.student_available_times) >=0){
        return (
          <div>
            {rowData.student_available_times}
          </div>
        )
      }else{
        return (
          <div style="color:red">
            {rowData.student_available_times}
          </div>
        )
      }
    },
    expandOptionRender(row, column, rowIndex){
      this.showShare = true
      return false;
      // if(row.customer_id){
      //   return (
      //     <div>
      //       <span on-click={()=>this.deleteStudent(row,rowIndex)} style="color:#D9534F;">移除学员</span>
      //       <span on-click={()=>this.jumpCustomer(row,rowIndex)} style="margin-left:20px;">客户信息</span>
      //     </div>
      //   )
      // }else{
      //   return (
      //     <div>
      //       <span on-click={()=>this.deleteStudent(row,rowIndex)} style="color:#D9534F;">移除学员</span>
      //     </div>
      //   )
      // }
    },
    toChat(rowData){
      if(this.isWxWork && rowData.wxwork_user_id){
          wx.openEnterpriseChat({
              userIds: '',    //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
              externalUserIds: rowData.wxwork_user_id, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
              groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
              chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
              success: function(res) {
                  var chatId = res.chatId; //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
                  // 回调
              },
              fail: function(res) {
                  if(res.errMsg.indexOf('function not exist') > -1){
                      alert('版本过低请升级')
                  }
              }
          });
      }
      

    },
    jumpStudent(item,index){
      this.$router.push({path:'/qywx/crm/profile',query:{student_id:item.student_id,external_userid:item.student_id}})
    },
    jumpCustomer(item,index){
      let appid=localStorage.getItem('appid');
      if(appid=='mMKcL4'){
        this.$router.push({path:'/custom/huidong/customer/detail',query:{customer_id:item.customer_id}})  
      }else{
        this.$router.push({path:'/crm/customer/detail',query:{customer_id:item.customer_id}})
      }  
    },
    deleteStudent(item,index){
      var that = this
      this.$dialog.confirm({
        title: '移除提示',
        message: '确认移除该学员',
      })
      .then(()=>{

        if(that.is_waitlist){
          Dialog.confirm({
            message: '当前排课包含等位学员，请确认是否自动补位?',
            confirmButtonText	:"自动补位",
            cancelButtonText:"不补位"
          })
            .then(() => {
              that.$api.employee_schedule_remove_member({book_id:item.book_id,auto_wait:1,course_class_id:that.$route.query.course_class_id})
              .then(res=>{
                that.$toast('移除成功～')
                that.is_waitlist=false
                that.onDetail();
              })
              // on confirm
            })
            .catch(() => {
              that.$api.employee_schedule_remove_member({book_id:item.book_id,course_class_id:that.$route.query.course_class_id})
              .then(res=>{
                that.$toast('移除成功～')
                that.course_students.splice(index,1)
              })
              // on cancel
            });

        }else{
            that.$api.employee_schedule_remove_member({book_id:item.book_id,course_class_id:that.$route.query.course_class_id})
            .then(res=>{
              that.$toast('移除成功～')
              that.course_students.splice(index,1)
            })
        }
        this.showShare = false;
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .card{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    background: #fff;
    &-media{
      width: 130px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
    }
    &-inner{
      flex: 1;
    }
    &-title{
      max-width: 140px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }

  .studen-list{

  }

  .studen-list-item{
    padding: 10px 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-media{
      margin-right: 10px;
      width: 36px;
      height: 36px;
      &-img{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &-icon{
      background: #5499f6;
      color: #fff;
      display: inline-block;
      border-radius: 4px;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      margin-right: 5px;
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    &-inner{
      // padding-right: 30px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
    &-stepper{
      display: flex;
      align-items: center;
      .van-stepper{
        margin: 0 5px;
      }
    }
  }

  .grid{
    &-item{
      /deep/ .van-grid-item__content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
      &-icon{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #00DE93;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #00DE93;
        margin-bottom: 5px;
        margin-right: 10px;
      }
      &-name{
        font-size: 14px;
        color: #333;
      }
    }
  }
  .footer{
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px;
    &-left{
      font-size: 16px;
      color: #333;
      flex: 1;
    }
    .van-button{
      margin-left: 10px;
    }
  }

  .affix-bar{
    background: #fff;
    display: flex;
    z-index: 10;
  }
  .checkin-add{
    position: fixed;
    bottom: 80px;
    right: 10px;
  }
</style>

<style lang="less">
  .checkin-user{
    display: flex;
    align-items: center;
    &-img{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 5px;
        margin-left: 10px;
    }
    &-demo{
      margin-left:5px;
      height: 16px;
    }
    &-name{
      display: inline-block;
      width: 70px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      text-align:left;
    }
    &-tag{
      margin-left:5px;
      font-size: 12px;
      background: #00DE93;
      height: 16px;
      display: flex;
      align-items: center;
      padding: 0 3px;
      color: #fff;
      border-radius: 2px;
    }
  }
  .v-table-views{
    .checkin-input{
      width: 55px;
      height: 30px;
      border: 1px solid #e8e8e8;
    }
    .checkin-input-span{
      font-size: 12px;
    }
    .v-checkbox-indeterminate .v-checkbox-inner{
      background-color: #00cca2;
      border-color: #00cca2;
    }
    .v-checkbox-checked .v-checkbox-inner{
      background-color: #00cca2;
      border-color: #00cca2;
    }
  }
  .checkin-html{
    img{
      max-width: 100%;
    }
  }
  // .checkin-user-name{
  //   color: #00cca2;
  // }
  .v-table-empty-data{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 100%;
    color: #666;
    font-size: 14px;
    border: 1px solid #eee;
    border-top: 0;
  }
  .checkin-table .ve-checkbox .ve-checkbox-checked .ve-checkbox-inner{
    background-color: #00cca2;
    border-color: #00cca2;
  }
  .checkin-table .ve-checkbox .ve-checkbox-indeterminate .ve-checkbox-inner:after{
    background-color: #00cca2;
  }
  .checkin-table .ve-checkbox-content:hover .ve-checkbox-inner{
    border-color: #00cca2;
  }
</style>

